var pixelockControllers = angular.module('pixelockControllers', []);
pixelockControllers.controller('SystemController', ['$rootScope', '$scope', '$state', '$uibModal', 'ngToast', 'apiRequest', 'cookieManagement', '$auth', 'config', 'Help', 'systemService',
    function($rootScope, $scope, $state, $uibModal, ngToast, apiRequest, cookieManagement, $auth, config, Help, systemService) {


        $("#lockCanvas").mCustomScrollbar({
            theme: "dark",
            axis: "yx" // vertical and horizontal scrollbar
        });

        $scope.closedAlerts = [];
        $scope.helpData = null;
        $scope.showHelpPanel = true;
        $scope.apiURL = config.apiURL;
        $scope.user = cookieManagement.getCookie("user");


        $scope.systemFunctions = {};
        $scope.system = {
            loggedIn: false,
            loading: false,
            toState: ""
        };

        $scope.alert = {
            class: "",
            message: ""
        };

        $scope.$on('onBeforeUnload', function(e, confirmation) {
            confirmation.message = "Your session will no longer be valid and you will be logged out of the website.";
            e.preventDefault();
        });

        $scope.$on('onUnload', function(e) {

            $scope.logout();
        });

        $scope.$on('onLoad', function(e) {
            /*
             *unload doesnt seem to work all the time so hack it on load
             */
            if ($auth.isAuthenticated()) {
                $auth.logout();
                $scope.user = undefined;
                cookieManagement.removeCookie("user");
                $state.go('publicindex');
                cookieManagement.setCookie('hasJustLanded',true);
            }
        });

        $scope.systemFunctions.closeAlert = function(id) //doesnt really close the alert simply pushes it in closed ones
            {
                $scope.closedAlerts.push(id);
            };

        $scope.systemFunctions.isAlertClosed = function(id) {
            return $scope.closedAlerts.indexOf(id) !== -1;
        };

        $scope.openHelp = function(help) {
            var helpmodalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'views/elements/help.html',
                controller: 'HelpController',
                size: 'lg',
                resolve: {
                    items: function() {
                        return help;
                    }
                }
            });
        };

        $scope.helptext = null;
        $scope.helptext_shown = false;
        $scope.showHelpText = function() {
            $scope.helptext_shown = !$scope.helptext_shown;
        };

        function setAlert(params, response) {
            if (response[0] === 'success') {
                $scope.alert.class = "alert-success";
            } else if (response[0] === 'error') {
                $scope.alert.class = "alert-danger";
            }
            $scope.alert.message = response[1];
        }

        function clearAlert() {
            $scope.alert.class = "";
            $scope.alert.message = "";
        }

        $scope.$on('SET_ALERT', setAlert);
        $scope.$on('CLEAR_ALERT', clearAlert);

        function closeMenu() {
            $('#modal-fullscreen').modal('hide');
        }

        function checkLogin() {
           
            $scope.system.loggedIn = $auth.getToken();
            return $scope.system.loggedIn != null;
        }

        function stateChange(event, toState, toParams, fromState, fromParams) {
            ensureHasHelp(toState.url); //will retrive and ensure that the is help conten from the server
            $scope.user = cookieManagement.getCookie("user");
            populateHelpText(toState.url);
            checkLogin();
            closeMenu();
            redirectIfNotAllowed(toState);
            clearAlert();
            $scope.errors = [];
            $scope.system.toState = toState.name;
            $("#pixel-main-nav").collapse("hide");
            $scope.helptext_shown = false;
            $("#float-help-section").removeClass("active");
        }
        var populateHelpText = function(url) {
            fullUrl = $state.get($state.$current.name).url;

            if (angular.isArray($scope.helpData) && $scope.helpData.length > 0) {
                var item = $.grep($scope.helpData, function(e) {
                    return e.page_url === fullUrl;
                });


                if (item.length > 0) {
                    $scope.helptext = item[0];
                } else {
                    $scope.helptext = null;
                    $scope.showHelpPanel = false;
                }
            } else {
                $scope.showHelpPanel = false;

            }
        };

        var ensureHasHelp = function(url) {
            if (!$scope.helpData) {
                apiRequest.send({
                    'url': 'help-data',
                    'method': 'GET'
                }).then(function successCallback(response) {
                    $scope.helpData = response.data;
                    if (url) {
                        populateHelpText(url);
                    }
                });

            }
        };

        redirectIfNotAllowed = function(toState) {
            var currentRole;

            if ($scope.system.loggedIn) {
                currentRole = 'loggedin';
            } else {
                currentRole = 'guest';
            }

            if (currentRole == "guest" && toState.roles.indexOf(currentRole) === -1) {
                $scope.user = undefined;
                cookieManagement.removeCookie("user");
                //@todo flash message for please login
                $state.go('auth.login');
            } else if (currentRole == "loggedin" && toState.roles.indexOf(currentRole) === -1) {
                $state.go('dashboard');
            }
        };

        function startLoad() {
            $scope.system.loading = true;
        }

        function stopLoad() {
            $scope.system.loading = false;
        }
        $scope.$on('AUTH_CHANGE', checkLogin);
        $scope.$on('SIGNOUT', signOut);
        $scope.$on('SESSIONEXPIRED', function(event,intendedState) 
          {
            ngToast.create({
                className: 'danger',
                content: 'Your session has expired. Please log in again'
            });

            $auth.logout();
            $scope.user = undefined;
            cookieManagement.removeCookie("user");
           cookieManagement.setCookie('intendedState',intendedState);

            $state.go('auth.login');
        });

       

        function signOut(goHome) {
            $auth.logout();
            $scope.user = undefined;
            cookieManagement.removeCookie("user");
            if (goHome) {
                $state.go('publicindex');
            } else {
                $state.go('auth.login');
            }
        }

        $rootScope.$on('$stateChangeSuccess', stateChange);
        $scope.$on('START_LOADING', startLoad);
        $scope.$on('STOP_LOADING', stopLoad);

        // $rootScope.$on('$stateChangeSuccess', stateChange);
        $scope.logout = function(goHome) {

            apiRequest.send({
                'url': 'logout',
                'method': 'GET'
            }).then(function successCallback(response) {
                    signOut();
                },
                function errorCallback(response) {

                    if (angular.isDefined(goHome) && goHome) {
                        signOut(true);
                    } else {
                        signOut(false);
                    }
                }
            );
        };
        checkLogin();
        ensureHasHelp();

    }
]);

pixelockControllers.controller('HelpController', ['$scope', '$state', '$sce', '$uibModalInstance', 'items', function($scope, $state, $sce, $uibModalInstance, items) {

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.myInterval = 5000;
    $scope.noWrapSlides = false;
    $scope.active = 0;
    $scope.help = {};
    //setup the help for the vid
    $scope.help.video = $sce.trustAsResourceUrl(items.fullVideoUrl);
    $scope.help.thumnail = $sce.trustAsResourceUrl(items.fullThumnailUrl);
    $scope.help.content = $sce.trustAsHtml(items.video_content);
    $scope.help.title = $sce.trustAsHtml(items.title);

}]);

pixelockControllers.controller('messageAlertController', ['$scope', '$uibModalInstance', 'items', function($scope, $uibModalInstance, items) {
    $scope.heading = items.heading;
    $scope.content = items.content;
    $scope.close = function() {
        $uibModalInstance.dismiss('cancel');
    };

}]);

pixelockControllers.controller('AuthController', ['$scope', '$state', '$auth', '$location', 'ngToast', 'apiRequest', 'cookieManagement', '$rootScope', '$stateParams', 'config',
    function($scope, $state, $auth, $location, ngToast, apiRequest, cookieManagement, $rootScope, $stateParams, config) {
        
        $scope.user = {};
        $scope.lockObj = {};
        $scope.errors = {};
        $scope.lockFunctions = {};
        $scope.lockDetails = null;
        $scope.successMessage = null;
        $scope.successResetMessage = null;
        $scope.emailCookies = cookieManagement.getCookie('emailCookies');
       // cookieManagement.setCookie('hasJustLanded',true);

        var registerUrl = config.appURL + '/#/auth' + $state.get('auth.register').url; //set full register url
        //var resetUrl = config.appURL+'/#'+$state.get('redoLoginLock').url; //this return with token so hardcode the url
        var resetUrl = config.appURL + '/#/redo-login-lock/';

        $scope.register = {
            email: "",
            security_question: "",
            security_answer: "",
            terms_and_conditions: 0,
            source_path: registerUrl
        };

        $scope.registerFunctions = {};
        $scope.reset = {};


        $rootScope.$on('$stateChangeSuccess', function() {
            $scope.errors = {};
        });

        if ($state.current.name == "auth.login") {
            $scope.loginMessage = $stateParams.message;

        } else {
            $scope.loginMessage = '';
        }

        


        $scope.registerFunctions.resend = function() {
            apiRequest.send({
                'url': 'resend-email',
                'method': 'POST',
                'data': {
                    source_path: registerUrl,
                    email: $scope.resendEmail
                }
            }).then(function successCallback(response) {
                $scope.errors = {}; //clear the errors
                $scope.successMessage = response.data.message[1];
                $scope.resendEmail = response.data['resend-email'];
                //$rootScope.$broadcast('SET_ALERT',response.data.message);
            }, function errorCallback(response) {

                $('#register_submit').button('reset');
                $scope.registerErrors = {};
                switch (response.status) {
                    case 422:
                        $scope.errors = response.data;
                        ngToast.create({
                            className: 'danger',
                            content: 'Whoops, something went wrong. Refer to the highlighted fields for more information.'
                        });
                        break;
                }

            });
        };


        $scope.registerFunctions.submit = function() {
            $('#register_submit').button('loading');
            apiRequest.send({
                'url': 'register',
                'method': 'POST',
                'data': $scope.register
            }).then(function successCallback(response) {
                $('#register_submit').button('reset');
                $scope.errors = {}; //clear the errors
                $scope.successMessage = response.data.message[1];
                $scope.resendEmail = response.data['resend-email'];
                //$rootScope.$broadcast('SET_ALERT',response.data.message);
            }, function errorCallback(response) {

                $('#register_submit').button('reset');
                $scope.registerErrors = {};
                switch (response.status) {
                    case 422:
                        $scope.errors = response.data;
                        ngToast.create({
                            className: 'danger',
                            content: 'Whoops, something went wrong. Refer to the highlighted fields for more information.'
                        });
                        break;
                }
            });
        };

        $scope.login = function() {
            $scope.errors = {};
            $auth.login($scope.user).then(function successCallback(response) {
                $scope.errors = {};
                cookieManagement.setCookie('user', response.data.user);


                addEmailCookies($scope.user.email);
                cookieManagement.setCookie('hasJustLanded',true);

                $scope.user = response.data.user;
                

                var intendedState = cookieManagement.getCookie('intendedState');
                if(angular.isUndefined(intendedState) || intendedState == null)
                {
                    $state.go('dashboard');
                }else
                {
                    cookieManagement.removeCookie('intendedState');
                    $state.go(intendedState.intendedState.name);
                }


            }, function errorCallback(response) {
                $scope.errors = {};
                switch (response.status) {
                    case 422:
                        $scope.errors = response.data;
                        ngToast.create({
                            className: 'danger',
                            content: 'Whoops, something went wrong. Refer to the highlighted fields for more information.'
                        });
                        $scope.user.password = null;
                        break;
                }
            });
        };

        addEmailCookies = function(email)
        {       

            if(typeof $scope.emailCookies == "undefined" || ($scope.emailCookies && $scope.emailCookies.length == 0))
            {
                console.log("undefined");
                $scope.emailCookies = [email];

            }else if($scope.emailCookies.indexOf(email) < 0)
            {
                console.log("email");
                $scope.emailCookies.push(email);
            }


            var now = new Date(),
            // this will set the expiration to 12 months
            exp = new Date(now.getFullYear()+1, now.getMonth(), now.getDate());
            // set 
            cookieManagement.removeCookie('emailCookies');
            cookieManagement.setCookie('emailCookies', $scope.emailCookies);
        }

        $scope.refillMail = function(mail)
        {
            $scope.user.email = mail;
            $scope.getLoginLock();
        };


        $scope.lockFunctions.repeatLoginLock = function() {
            
            $("#lockCanvas").html('');
            $scope.user.password = '';
            pixelockWidget.init({
                'uid': $scope.lockDetails.tag,
                'lockURL': $scope.lockDetails.uri,
                'lockName': $scope.lockDetails.name,
                'IDSelector': "lockCanvas",
                'source': 1,
                'login': true,
                'onComplete': $scope.login
            }).then(
                function successCallback(lock) {
                    $scope.$apply(function() {
                        $scope.lockObj = lock;
                        $scope.lockContent = lock.getLock();
                    });
                },
                function errorCallback(response) {
                    $scope.errors = {};
                    switch (response.status) {
                        case 422:
                            $scope.errors = response.data;
                            ngToast.create({
                                className: 'danger',
                                content: 'Whoops, something went wrong. Refer to the highlighted fields for more information.'
                            });
                            break;
                    }
                });
        };


        $scope.updatePasswordField = function() {
            $scope.user.password = $scope.lockObj.getGeneratedPassword();
        };

        $scope.getLoginLock = function() {
            $scope.errors = {};
            apiRequest.send({
                'url': 'get-login-lock',
                'method': 'POST',
                'data': {
                    'email': $scope.user.email
                }
            }).then(
                function successCallback(response) {
                    $scope.lockDetails = response.data.data;
                    $("#lockCanvas").html('');

                    pixelockWidget.init({
                        'uid': response.data.data.tag,
                        'lockURL': response.data.data.uri,
                        'lockName': response.data.data.name,
                        'IDSelector': "lockCanvas",
                        'source': 1,
                        'login': true,
                        'onComplete': $scope.login
                    }).then(
                        function successCallback(lock) {
                            $scope.$apply(function() {
                                $scope.lockObj = lock;
                                $scope.lockContent = lock.getLock();
                            });
                        },
                        function errorCallback(response) {
                            $scope.errors = {};
                            switch (response.status) {
                                case 422:
                                    $scope.errors = response.data;
                                    ngToast.create({
                                        className: 'danger',
                                        content: 'Whoops, something went wrong. Refer to the highlighted fields for more information.'
                                    });
                                    break;
                            }
                        });

                },
                function errorCallback(response) {
                    $scope.errors = {};
                    switch (response.status) {
                        case 422:
                            $scope.errors = response.data;
                            break;
                    }
                });
        };

        $scope.resetLock = function() {
            $scope.errors = {};

            if (!$scope.answerQuestion) {
                apiRequest.send({
                        'url': 'get-question',
                        'method': 'POST',
                        'data': $scope.reset
                    })
                    .then(function successCallback(response) {
                        $scope.user = {};
                        $scope.answerQuestion = true;
                        $scope.reset = response.data.user;

                    }, function errorCallback(response) {

                        $scope.answerQuestion = false;
                        $scope.errors = {};
                        switch (response.status) {
                            case 422:
                                $scope.errors = response.data;
                                ngToast.create({
                                    className: 'danger',
                                    content: 'Whoops, something went wrong. Refer to the highlighted fields for more information.'
                                });
                                break;
                        }
                    });
            } else {
        
                $scope.reset.source_path = resetUrl;
                apiRequest.send({
                        'url': 'answer-question',
                        'method': 'POST',
                        'data': $scope.reset
                    })
                    .then(function successCallback(response) {
                        $scope.user = {};
                        $scope.answerQuestion = true;
                        $scope.successResetMessage = response.data.message;

                    }, function errorCallback(response) {
                        $scope.errors = {};
                        switch (response.status) {
                            case 422:
                                $scope.errors = response.data;
                                break;
                        }
                    });

            }
        };

        $scope.user = $stateParams;
        $scope.resetPassword = function(email) {
            $scope.errors = {};

            apiRequest.send({
                    'url': 'password/reset',
                    'method': 'POST',
                    'data': $scope.user
                })
                .then(function successCallback(response) {
                    $rootScope.$broadcast('SET_ALERT', response.data.message);
                    $scope.user = {};
                }, function errorCallback(response) {
                    $scope.errors = {};
                    switch (response.status) {
                        case 422:
                            $scope.errors = response.data;
                            ngToast.create({
                                className: 'danger',
                                content: 'Whoops, something went wrong. Refer to the highlighted fields for more information.'
                            });
                            break;
                    }
                });
        };

    }
]);

pixelockControllers.controller('dashboardController', ['$scope', '$location', 'ngToast', 'apiRequest', 'cookieManagement', function($scope, $location, ngToast, apiRequest, cookieManagement) {
    $scope.user = cookieManagement.getCookie('user');
}]);




pixelockControllers.controller('categoryController', ['$scope', '$uibModal', 'ngToast', 'apiRequest', function($scope, $uibModal, ngToast, apiRequest) {
    $scope.categoryFunctions = {};
    $scope.categoryFunctions.init = function() {

        apiRequest.send({
                'url': 'category',
                'method': 'GET'
            })
            .then(function successCallback(response) {
                $scope.categories = response.data.data;
            }, function errorCallback(response) {

            });
    };

    $scope.categoryFunctions.delete = function(category) {
        apiRequest.send({
            'url': 'category/' + category.id,
            'method': 'DELETE'
        }).then(function successCallback(response) {
            $scope.categories = response.data.data;
        });
    };

    $scope.categoryFunctions.open = function() {

        var modalInstance = $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: 'views/category/category-form.html',
            controller: 'ManageCategoryController',
            backdrop: 'static',
            resolve: {
                category: function() {
                    return {
                        id: null,
                        'title': null,
                        'image': null
                    };
                }
            }
        });

        modalInstance.result.then(function(categories) {
            $scope.categories = categories;
        });
    };

    $scope.categoryFunctions.edit = function(category) {

        var modalInstance = $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: 'views/category/category-form.html',
            controller: 'ManageCategoryController',
            backdrop: 'static',
            resolve: {
                category: function() {
                    return category;
                }
            }
        });

        modalInstance.result.then(function(categories) {
            $scope.categories = categories;
        });
    };

    $scope.categoryFunctions.init();
}]);

// Please note that $uibModalInstance represents a modal window (instance) dependency.
// It is not the same as the $uibModal service used above.

pixelockControllers.controller('ManageCategoryController', ["$scope", "$uibModalInstance", "Upload", "ngToast", "apiRequest", "config", "category", function($scope, $uibModalInstance, Upload, ngToast, apiRequest, config, category) {
    $scope.category = category;

    var categoryUrl = config.apiURL + "category";

    $scope.errors = {};

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.store = function() {


        if ($scope.category.id) {
            $scope.category._method = "PUT";
            $scope.category.locks = undefined;
            categoryUrl = config.apiURL + "category/" + $scope.category.id;
        }


        Upload.upload({
            url: categoryUrl,
            data: $scope.category,
        }).then(function(response) {
            $uibModalInstance.close(response.data.data);
        }, function(response) {
            switch (response.status) {
                case 422:
                    $scope.errors = response.data;
                    ngToast.create({
                        className: 'danger',
                        content: 'Whoops, something went wrong. Refer to the highlighted fields for more information.'
                    });
                    break;
            }
        }, function(evt) {
            var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
        });
    };

    $scope.Imagechanged = function(image) {
        $scope.category.image = image;
    };



}]);

pixelockControllers.controller('offlineController', ['$scope', '$location', '$auth', '$state', 'ngToast', 'apiRequest', 'cookieManagement', function($scope, $location, $auth ,$state, ngToast, apiRequest, cookieManagement) {


    $scope.contactFunctions = {};
    $scope.successMessage = null;
    $scope.contact = {
        fullname: '',
        email: '',
        subject: '',
        message: ''
    };

        $scope.emailCookies = cookieManagement.getCookie('emailCookies');
        hasJustLanded = cookieManagement.getCookie('hasJustLanded');

    
      if((typeof $scope.emailCookies != "undefined" || $scope.emailCookies && $scope.emailCookies.length > 0) && hasJustLanded && !$auth.isAuthenticated())
        {
            cookieManagement.setCookie('hasJustLanded',false);
             $state.go("auth.login");
        }


    apiRequest.send({
        'url': 'testimonial'
    }).then(function successCallback(response) {
        $scope.quotes = response.data;
    });

    $scope.contactFunctions.contactUs = function() {
        apiRequest.send({
            'url': 'contact-us',
            'method': 'POST',
            'data': $scope.contact
        }).then(function successCallback(response) {
            if (response.data.message[0] === 'success') {
                $scope.completed = true;
                $scope.successMessage = response.data.message[1];
            }
        }, function errorCallback(response) {
            $scope.errors = {};
            switch (response.status) {
                case 422:
                    $scope.errors = response.data;
                    ngToast.create({
                        className: 'danger',
                        content: 'Whoops, something went wrong. Refer to the highlighted fields for more information.'
                    });
                    break;
            }
        });
    };

}]);

pixelockControllers.controller('completeRegistrationController', ['$scope', '$rootScope', '$state', '$stateParams', 'config', 'ngToast', 'apiRequest', 'cookieManagement', function($scope, $rootScope, $state, $stateParams, config, ngToast, apiRequest, cookieManagement) {
    $scope.user = {};
    $scope.loading = true;
    $scope.completeRegFunctions = {};
    var token = $stateParams.token;
    $scope.message = null;

    $scope.expired = false;
    $scope.created = false;
    $scope.completed = false;
    var registerUrl = config.appURL + '/#/auth' + $state.get('auth.register').url; //set full register url

    $scope.completeRegFunctions.submit = function() {
        $('#register_submit').button('loading');
        $scope.completeReg.is_active = true;
        apiRequest.setRegistrationToken(token);

        apiRequest.send({
            'url': 'savefromtoken',
            'method': 'PUT',
            'data': $scope.completeReg
        }).then(function successCallback(response) {
            $('#register_submit').button('reset');
            if (response.data.message[0] === 'success') {
                $scope.completed = true;
            }
            $rootScope.$broadcast('SET_ALERT', response.data.message);

            //allow users to create the lock for login
            $state.go("registerLock", {
                'user': response.data.user
            });

        }, function errorCallback(response) {
            $('#register_submit').button('reset');
            $scope.errors = {};
            switch (response.status) {
                case 422:
                    $scope.errors = response.data;
                    ngToast.create({
                        className: 'danger',
                        content: 'Whoops, something went wrong. Refer to the highlighted fields for more information.'
                    });
                    break;
            }
        });
    };

    apiRequest.send({
        'url': 'getfromtoken/' + token,
        'method': 'GET'
    }).then(function successCallback(response) {

            if (response.data.status === 'user_activated') {
                $scope.message = response.data.message[1];
                $scope.resendEmail = null;
            } else if (response.data.status === 'token_expired') {
                $scope.message = response.data.message[1];
                $scope.resendEmail = response.data['resend-email'];
            } else {
                $scope.created = false;
                $scope.expired = false;
                $scope.message = null;
                $scope.completeReg = response.data;
            }
            $scope.loading = false;
        },
        function errorCallback(response) {
            $scope.loading = false;
        });


    $scope.completeRegFunctions.resend = function() {
        apiRequest.send({
            'url': 'resend-email',
            'method': 'POST',
            'data': {
                source_path: registerUrl,
                email: $scope.resendEmail
            }
        }).then(function successCallback(response) {
            $scope.errors = {}; //clear the errors
            $scope.message = response.data.message[1];
            $scope.resendEmail = response.data['resend-email'];
            //$rootScope.$broadcast('SET_ALERT',response.data.message);
        }, function errorCallback(response) {

            $('#register_submit').button('reset');
            $scope.registerErrors = {};
            switch (response.status) {
                case 422:
                    $scope.errors = response.data;
                    ngToast.create({
                        className: 'danger',
                        content: 'Whoops, something went wrong. Refer to the highlighted fields for more information.'
                    });
                    break;
            }

        });
    };

}]);



function chunk(arr, size) {
    var newArr = [];
    for (var i = 0; i < arr.length; i += size) {
        newArr.push(arr.slice(i, i + size));
    }
    return newArr;
}

pixelockControllers.controller('GalleryController', ['$scope', '$uibModal', 'ngToast', 'apiRequest', 'cookieManagement', function($scope, $uibModal, ngToast, apiRequest, cookieManagement) {
    $scope.images = [];
    $scope.chunkedImages = [];
    $scope.image = '';
    $scope.loading = true;
    $scope.imagesPaginate = [];
    var user = cookieManagement.getCookie("user");

    var imagesInit = function(page) {
        page = page || 1;
        var params = {
            'page': page
        };

        apiRequest.send({
            'url': 'image',
            'method': 'GET',
            'params': params
        }).
        then(function successCallback(response) {

            $scope.images = [];
            $scope.images.push({
                'dummyImage': true
            });
            $scope.images = $scope.images.concat(response.data.data);
            $scope.chunkedImages = chunk($scope.images, 6);
            $scope.imagesPaginate = response.data;

        }, function errorCallback(response) {});
    };

    $scope.$on('paginate.changed', function(evt, data) {
        imagesInit(data);
    });

    $scope.openLarge = function(image) {
        var modalInstance = $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: 'views/gallery/show.html',
            controller: 'imageShowController',
            size: 'md',
            resolve: {
                item: function() {
                    return image;
                }
            }
        });

        modalInstance.result.then(function(images) {
            imagesInit(images);
        });
    };

    $scope.deleteImage = function(image) {
        var modalInstance = $uibModal.open({
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl: 'views/gallery/delete.html',
            controller: 'imageDeleteController',
            size: 'sm',
            resolve: {
                image: function() {
                    return image;
                }
            }
        });

        modalInstance.result.then(function(images) {
            imagesInit(images);
        });
    };

    $scope.open = function() {
        var modalInstance = $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: 'views/gallery/add-image.html',
            controller: 'imageUploadController',
            size: 'md',
            backdrop: 'static',
            resolve: {
                item: function() {
                    return {
                        'image': $scope.image,
                        'user': user
                    };
                }
            }
        });

        modalInstance.result.then(function(images) {
            $scope.imagesPaginate = images.data;
            $scope.images = [];
            $scope.images.push({
                'dummyImage': true
            });
            $scope.images = $scope.images.concat(images.data);
            $scope.chunkedImages = chunk($scope.images, 6);
        });
    };

    imagesInit();
}]);

pixelockControllers.controller('imageShowController', ["$scope", "$uibModalInstance", "ngToast", "apiRequest", "cookieManagement", "item", function($scope, $uibModalInstance, ngToast, apiRequest, cookieManagement, item) {
    $scope.image = item;
    $scope.user = cookieManagement.getCookie("user");
    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };
    $scope.setDefault = function() {
        apiRequest.send({
            'url': 'toggle-default-image/' + $scope.image.id,
            'method': 'POST'
        }).then(function successCallback(response) {
                ngToast.create({
                    className: 'success',
                    content: 'The image public availabilty has been changed.'
                });

                $uibModalInstance.close(response.data);
            },
            function errorCallback(response) {
                $scope.message = response.data.message;
                $scope.fail = true;
            });
    };

}]);

pixelockControllers.controller('imageDeleteController', ["$scope", "$uibModalInstance", "ngToast", "apiRequest", "image", function($scope, $uibModalInstance, ngToast, apiRequest, image) {
    $scope.image = image;
    $scope.message = "Are you sure that you want to delete the selected image ?";
    $scope.fail = false;

    $scope.deleteImage = function() {
        apiRequest.send({
            'url': 'image/' + image.id,
            'method': 'DELETE'
        }).then(function successCallback(response) {
                $uibModalInstance.close(response.data);
            },
            function errorCallback(response) {
                $scope.message = response.data.message;
                $scope.fail = true;
            });
    };

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };
}]);
pixelockControllers.controller('imageUploadController', ["$scope", "$uibModalInstance", "ngToast", "apiRequest", "item", function($scope, $uibModalInstance, ngToast, apiRequest, item) {

    $scope.image = '';
    $scope.croppedImage = '';
    $scope.is_default = false;
    $scope.user = item.user;
    $scope.hasImage = false;
    var image = item.image;

    $scope.fileChanged = function(image) {
        var name = $(image).get(0).files[0].name;

        if (!name.match(/\.(jpg|jpeg|png|gif)$/)) {
            ngToast.create({
                className: 'danger',
                content: 'Please ensure that the uploaded file is an image.'
            });
        } else {
            $scope.hasImage = true;
            if ($(image).get(0).files[0]) { 
                var file = $(image).get(0).files[0];
                var reader = new FileReader();
                reader.onload = function(evt) {
                    $scope.$apply(function($scope) {
                        $scope.image = evt.target.result;
                    });
                };
                reader.readAsDataURL(file);
            }
        }

    };



    $scope.ok = function() {
        // var file = new File([dataURItoBlob($scope.croppedImage)], 'sample', {
        //     // lastModified: new Date(0), // optional - default = now
        //     // type: "overide/mimetype" // optional - default = ''
        // });
        var file = dataURItoBlob($scope.croppedImage); 

        file.name = "croppedImage";
        file.lastModifiedDate = new Date();

        var is_default = $scope.is_default && $scope.user.is_admin;
        var data = {
            'image': file,
            'is_default': is_default
        };

        apiRequest.sendWithFile({
                'url': 'image',
                'method': 'POST',
                'data': data
            })
            .then(function successCallback(response) {
                    $scope.errors = {};
                    $uibModalInstance.close(response.data);
                },
                function errorCallback(response) {
                    $scope.errors = {};
                    switch (response.status) {
                        case 422:
                            $scope.errors = response.data;
                            ngToast.create({
                                className: 'danger',
                                content: 'Whoops, something went wrong. Refer to the highlighted fields for more information.'
                            });
                            break;
                    }
                });


    };

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };

}]);

pixelockControllers.controller('viewLockController', ["$scope", "$stateParams", "$state", "$uibModal", "$auth", "ngToast", "apiRequest", "cookieManagement", function($scope, $stateParams, $state, $uibModal, $auth, ngToast, apiRequest, cookieManagement) {
    $scope.lock = null;
    $scope.website = null;
    $scope.lockObj = {};
    $scope.user = cookieManagement.getCookie("user");
    $scope.lockContent = {};
    $scope.generatedPassword = '';
    $scope.lockFunctions = {};
    $scope.revealedFields = [];

    $scope.lockFunctions.init = function() {
        $("#lockShow").empty();
        $scope.generatedPassword = '';

        if (!$.isEmptyObject($stateParams.lock) && $scope.lock === null) {
            $scope.lock = $stateParams.lock;
        }

        if ($scope.lock !== null) {
            $("#lockShow").empty();
            openWidget();

    

            if($scope.lock.site_url && !$scope.lock.site_url.includes('http'))
            {
                $scope.website = "//"+$scope.lock.site_url;
            }else
            {
                $scope.website = $scope.lock.site_url;
            }

        } else {
            apiRequest.send({
                    'url': 'lock/' + $stateParams.id,
                    'method': 'GET'
                })
                .then(function successCallback(response) {
                        $scope.lock = response.data;
                        $("#lockShow").empty();
                        openWidget(); 
                        if(!$scope.lock.site_url.includes('http'))
                        {
                            $scope.website = "//"+$scope.lock.site_url;
                        }else
                        {
                            $scope.website = $scope.lock.site_url;
                        }
                    },
                    function errorCallback(response) {

                    });
        }
    };
    openWidget = function() {
        pixelockWidget.init({
            'uid': $scope.user.tag,
            'lockURL': $scope.lock.uri,
            'lockName': $scope.lock.name,
            'IDSelector': "lockShow",
            'authToken': $auth.getToken(),
            'onComplete': null,
            'source': 1
        }).then(function(lock) {
            $scope.$apply(function() {
                
                $scope.detailLockObj = lock;
                $scope.detailLockContent = lock.getLock();


            });
        });
    };

    $scope.lockFunctions.updatePasswordField = function() {
        $scope.generatedPassword = $scope.detailLockObj.getGeneratedPassword();
    };

    $scope.lockFunctions.revealField = function(field) {

        if($scope.revealedFields.indexOf(field) == -1)
        {
            $scope.revealedFields.push(field);
        }else
        {
            var index = $scope.revealedFields.indexOf(field);
            $scope.revealedFields.splice(index,1);

        }

    };



    $scope.lockFunctions.revealedField = function(field) {
        return $scope.revealedField == field;
    };

    $scope.lockFunctions.delete = function() {
        $scope.fail = false;

        deleteModalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'views/lock/delete.html',
            controller: 'deleteLockController',
            size: 'sm',
            resolve: {
                lock: function() {
                    return $scope.lock;
                }
            }
        });
        deleteModalInstance.result.then(function(locks) {
            $state.go('dashboard');
        });
    };

    $scope.lockFunctions.init();

}]);

pixelockControllers.controller('deleteLockController', ["$scope", "$uibModalInstance", "ngToast", "apiRequest", "lock", function($scope, $uibModalInstance, ngToast, apiRequest, lock) {
    $scope.lock = lock;

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.destroy = function() {
        apiRequest.send({
            'url': 'lock/' + lock.id,
            'method': 'DELETE'
        }).then(function successCallback(response) {
            $uibModalInstance.close(response.data.data);
            $uibModalInstance.dismiss('cancel');
        });
    };

}]);

pixelockControllers.controller('LiteController', ["$scope", "$stateParams", "$state", "ngToast", "apiRequest", function($scope, $stateParams, $state, ngToast, apiRequest) {

    $scope.activeCat = null;
    $scope.categories = [];
    $scope.liteFunctions = {};
    $scope.pagination = {};
    $scope.liteFunctions.updatePasswordField = function() {
        $scope.generatedPassword = $scope.lockObj.getGeneratedPassword();
    };


    $scope.liteFunctions.getByCategory = function(categoryId) {
        apiRequest.send({
            'url': 'public-lock',
            'params': {
                'category_id': categoryId
            },
            'method': 'GET'
        }).then(function successCallback(response) {
                $scope.pagination = response.data;
                $scope.locks = [];
                $scope.locks = response.data.data;
                $scope.loading = false;
                $scope.chunkedLocks = chunk($scope.locks, 6);

            },
            function errorCallback(response) {});

    };


    var publicLocksInit = function() {

        apiRequest.send({
            'url': 'category/list',
            'method': 'GET'
        }).then(function successCallback(response) {
                $scope.categories = response.data;
            },
            function errorCallback(response) {});
    };

    var categoryInit = function(page, categoryId) {

        page = page || 1;
        var params = {
            'page': page,
            'category_id': categoryId
        };

        apiRequest.send({
            'url': 'public-lock',
            'method': 'GET',
            'params': params
        }).then(function successCallback(response) {
                $scope.locks = [];
                $scope.locks = response.data.data;
                $scope.pagination = response.data;
                $scope.pagination.data = null; //remove info not needed        
                $scope.loading = false;
                $scope.chunkedLocks = chunk($scope.locks, 6);

            },
            function errorCallback(response) {});
    };


    $scope.$on('paginate.changed', function(evt, data) {
        categoryInit(data, activeCat);
    });

    var publicLockView = function() {
        $scope.lock = $stateParams.lock;
        if ($scope.lock !== null) {
            openWidget();
        } else {
            apiRequest.send({
                    'url': 'lite/' + $stateParams.id,
                    'method': 'GET'
                })
                .then(function successCallback(response) {
                        $scope.lock = response.data;
                        openWidget();
                    },
                    function errorCallback(response) {

                    });
        }
    };

    var openWidget = function() {
        pixelockWidget.init({
            'IDSelector': "lockCanvas",
            'source': 1,
            'lock': $scope.lock.file,
            'onComplete': null,
        }).then(function(lock) {
            $scope.$apply(function() {
                $scope.lockObj = lock;
            });
        });
    };

    switch ($state.current.name) {
        case "lite":
            publicLocksInit(1);
            break;
        case "liteCategory":
            $scope.activeCat = $stateParams.categoryId;
            $scope.categoryName = $stateParams.categoryName;
            categoryInit(1, $scope.activeCat);
            break;
        case "liteView":
            publicLockView();
            break;
    }

}]);



pixelockControllers.controller('LockController', ['$q', '$scope', '$rootScope', '$uibModal', '$state', '$stateParams', '$auth', 'config', 'ngToast', 'apiRequest', 'cookieManagement', 'systemService',
    function($q, $scope, $rootScope, $uibModal, $state, $stateParams, $auth, config, ngToast, apiRequest, cookieManagement, systemService) {

        var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        var finalObj;
        $scope.iOS = iOS;
        $scope.user = cookieManagement.getCookie("user");
        $scope.registration = false;
        if ($scope.user === null) {
            $scope.user = $stateParams.user;
        }
        $scope.lockNotStepsDone = true;
        $scope.locks = [];
        $scope.LockPagination = [];
        $scope.chunkedLocks = [];
        $scope.system.loading = true;
        $scope.generatedPassword = ''; //password generated from the pattern
        $scope.generatedConfirmPassword = ''; //confirm password that was generated
        $scope.lockModel = {}; //the actual database model sent from server
        var storedClicks = [];
        $scope.symbols = [{
            allowed: false,
            value: "~"
        }, {
            allowed: false,
            value: "!"
        }, {
            allowed: false,
            value: "@"
        }, {
            allowed: false,
            value: "#"
        }, {
            allowed: false,
            value: "$"
        }, {
            allowed: false,
            value: "%"
        }, {
            allowed: false,
            value: "^"
        }, {
            allowed: false,
            value: "&"
        }, {
            allowed: false,
            value: "*"
        }, {
            allowed: false,
            value: "("
        }, {
            allowed: false,
            value: ")"
        }, {
            allowed: false,
            value: "_"
        }, {
            allowed: false,
            value: "+"
        }, {
            allowed: false,
            value: "-"
        }, {
            allowed: false,
            value: "/"
        }, {
            allowed: false,
            value: "."
        }];
        $scope.images = [];
        $scope.categoryList = [];
        $scope.activeTab = 1;
        $scope.publicLock = false;
        $scope.chunkedImages = [];
        $scope.selectedImages = [];
        $scope.imagesPaginate = [];
        $scope.grouped = [];
        $scope.changePasswordState = false;
        $scope.disableLockFields = false;
        $scope.editState = false;
        $scope.lock = {
            images: [],
            imageIds: [],
            uppercase: true,
            lowercase: true,
            numbers: true,
            length: 6,
            symbols: [],
            domain: '',
            category_id: null,
            includeSymbols: true,
            fields: [],
        };
        $scope.lockObj = {
            images: [],
            gridcols: 0,
            gridrows: 0,
            pgrid: null
        };
        $scope.lockNames = [];



        //if old password isset in the stateparams set it to scope as you can allow user to change password
        $scope.passwordChangeValues = {
            'old_password': $stateParams.old_password,
            'new_password': null,
            'confirm_password': null
        };

        //use this to ensure that the password exist
        $scope.generatedPasswords = {
            'password': null,
            'confirm_password': null
        };

        $scope.lockFunctions = {};
        var deleteModalInstance;

        $scope.open = function(lock) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'views/lock/view.html',
                controller: 'viewLockController',
                size: 'lg',
                resolve: {
                    lock: function() {
                        return lock;
                    }
                }
            });
        };


        //opening a modal to add a new image
        $scope.lockFunctions.open = function(size) {
            var modalInstance = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: 'views/gallery/add-image.html',
                controller: 'imageUploadController',
                size: size,
                backdrop: 'static',
                resolve: {
                    item: function() {
                        return {
                            'image': $scope.image,
                            'user': $scope.user
                        };
                    }
                }
            });

            //after an image has been added through the modal update the new images
            modalInstance.result.then(function(images) {
                setGallery();
            });
        };

        $scope.lockFunctions.activateSymbols = function() {
            angular.forEach($scope.symbols, function(symbol) {
                if ($scope.lock.includeSymbols) {

                    if ($scope.lock.symbols.indexOf(symbol.value) === -1) {
                        this.push(symbol.value);
                        symbol.allowed = true;
                    }

                } else {
                    $scope.lock.symbols = [];
                }
            }, $scope.lock.symbols);
        };

        $scope.lockFunctions.toggleSymbol = function(symbol) {
            if ($scope.lock.symbols.indexOf(symbol.value) === -1) {
                $scope.lock.symbols.push(symbol.value);
                symbol.allowed = true;
            } else {
                var index = $scope.lock.symbols.indexOf(symbol.value);
                if (index !== -1) {
                    $scope.lock.symbols.splice(index, 1);
                }
                symbol.allowed = false;
            }

        };


        $scope.lockFunctions.addImage = function(image) {
            $scope.lock.images.push(image.url);
            $scope.lock.imageIds.push(image.id);
            image.orders.push($scope.lock.images.length);
        };

        $scope.lockFunctions.removeImage = function(index) {
            if (index !== -1) {
                $scope.lock.images.splice(index, 1);
                $scope.lock.imageIds.splice(index, 1);
                angular.forEach($scope.images, function(image) {
                    image.orders = [];
                    for (var i = 0; i < $scope.lock.images.length; i++) {
                        if ($scope.lock.images[i] === image.url) {
                            order = i + 1;
                            image.orders.push(order);
                        }
                    }
                });
            }
        };

        $scope.lockFunctions.addField = function() {
            var field = {
                name: '',
                value: ''
            };
            $scope.lock.fields.push(field);
        };

        $scope.lockFunctions.removeField = function(index) {
            if (index !== -1) {
                $scope.lock.fields.splice(index, 1);
            }
        };


        $scope.lockFunctions.storeLock = function(step, edit) {

            var promise = $q.defer();
            var method = null;
            var url = null;
            var data = {};

            if ($scope.lock.id == null) {
                method = 'POST';
            } else {
                method = 'PUT';
            }

            if ($scope.publicLock) {
                url = 'set-public-lock';
            } else {
                url = 'set-lock';
            }

            /*
             *reset login password set a the token ffrom state params
             */
            if ($state.current.name == 'redoLoginLock') {
                data = $scope.lock;
                data.reset_token = $stateParams.reset_token;
            } else {
                data = angular.copy($scope.lock);
            }

            if (angular.isDefined(step)) {
                data.step = step;
            } else {
                data.step = null;
            }

            if ($scope.lockObj.images && !$scope.lock.images) {
                data.images = $scope.lockObj.images;
            }

            if(!$scope.editState)
            {
               data.gridcols = $scope.lockObj.gridcols;
               data.gridrows = $scope.lockObj.gridcols;
               data.alphabet = $scope.lockObj.alphabet;
               data.pgrid = $scope.lockObj.pgrid;    
            }
          


            data.fields = $scope.lock.fields;
            apiRequest.send({
                    'url': url,
                    'method': method,
                    data: data
                })
                .then(function successCallback(response) {

                        /* 
                         if($scope.publicLock)
                         {
                         $state.go('adminPublicLocks');
                         }
                         */

                        promise.resolve(response);
                    },
                    function errorCallback(response) {
                        $scope.errors = {};
                        switch (response.status) {
                            case 422:
                                $scope.errors = response.data;
                                ngToast.create({
                                    className: 'danger',
                                    content: 'Whoops, something went wrong. Refer to the highlighted fields for more information.'
                                });
                                break;
                        }
                        // create a toast with settings:

                        promise.reject(response);
                    });

            return promise.promise;
        };

        deleteLocks = function() {
            $("#lockCanvas").empty();
            $("#confirmLockCanvas").empty();
            $("#lockShow").empty();
        };

        /*
         * form wizard methods, for lock creation on registration,password change and nomarl lock creation
         */

        //check if the person is allowed on the step
        var isAuthorizedToStep = function(step) {
            var isAuthorized = false;
            var passwordMatch = $scope.generatedPasswords.confirm_password == $scope.generatedPasswords.password;

            var lockStepsFine = $scope.lock.name && $scope.lock.images.length > 0;

            if ((step == 1 && $scope.registration === true) || (step == 1 && $scope.changePasswordState === true)) {
                //if they try and go to first set in registartion take them to the select images step
                $scope.lockFunctions.selectImages();
                return isAuthorized;
            } else if ((step == 3 && !lockStepsFine && $scope.registration === false) || step == 3 && !lockStepsFine && $scope.changePasswordState === false) {
                $scope.lockFunctions.setCriteria();
                return isAuthorized;
            } else if ((step == 3 && !lockStepsFine && $scope.registration === true) || (step == 3 && !lockStepsFine && $scope.changePasswordState === true)) {
                $scope.lockFunctions.selectImages();
                return isAuthorized;
            } else if (!(!!$scope.generatedPasswords.password) && step == 4) {
                $scope.lockFunctions.enterPattern(); //take them back as they have not set a password
                return isAuthorized;

            } else if ((step == 5 && !lockStepsFine && $scope.registration === false) || (step == 5 && !lockStepsFine && $scope.changePasswordState === false)) {
                $scope.lockFunctions.setCriteria();
                return isAuthorized;
            } else if ((step == 5 && !lockStepsFine && $scope.registration === true) || (step == 5 && !lockStepsFine && $scope.changePasswordState === true)) {
                $scope.lockFunctions.selectImages();
                return isAuthorized;
            } else if (step == 5 && !passwordMatch) {

                $scope.lockFunctions.enterPattern(); //take as the passwords did not match
                return isAuthorized;
            }

            return true;
        };

        $scope.lockFunctions.revealField = function(field) {
            $scope.revealedField = field;
        };

        $scope.lockFunctions.hideField = function() {
            $scope.revealedField = null;
        };

        $scope.lockFunctions.revealedField = function(field) {
            return $scope.revealedField == field;
        };

        $scope.lockFunctions.updatePasswordField = function() { 
            angular.isDefined(finalObj)
            {
                $scope.generatedPasswords.password = finalObj.getGeneratedPassword(); 
            }
        };

        setFinalObj = function(data)
        {
            finalObj = data;
        }

        //step 1
        $scope.lockFunctions.setCriteria = function() {

            if (isAuthorizedToStep(1)) {
                $scope.activeTab = 1;
            }
        };

        //step 2
        $scope.lockFunctions.selectImages = function() {
            if ($scope.editState == true) {
                $scope.lockFunctions.viewDetails();
            }else
            {
                var validCriteria = false;
                //validate step then do wasup
                $scope.lockFunctions.storeLock(0).then(function(data) {
                    validCriteria = data;

                    if (isAuthorizedToStep(2)) {
                        $scope.activeTab = 2;
                    }
                }, function(data) {
                    validCriteria = data;
                });   
            }

    

        };

        setRandomPattern = function(lockData) {
            $scope.lockObj = lockData;
            $scope.$apply(); //refresh here as ran trough plugin

            pixelockWidget.init({
                'uid': $scope.user.tag,
                'authToken': $auth.getToken(),
                'lockURL': $scope.lock.domain, //this should not be required
                'lockName': $scope.lock.name, //this should not be required
                'IDSelector': "confirmLockCanvas",
                'source': 1,
                'onComplete': storeRandom,
                'lock': lockData
            });
            performRandomClicks(true);
        };

        storeRandom = function() {
            $scope.lockFunctions.storeLock().then(function(data) {
                $state.go('adminPublicLocks', {
                    message: 'You have created a lock'
                });
            });
        };

        performRandomClicks = function(stored) {
            stored = typeof stored == 'undefined' ? false : stored;
            var length = Math.round((Math.random() * 28) + 4);
            var clicks = [];
            var it = 0;
            var speed = 5;
            var numOfClicks = $scope.lock.images.length;
            for (var itt = 0; itt < numOfClicks; itt++) {
                if (!stored) {
                    storedClicks = [];
                    setTimeout(function() {
                        storedClicks[itt] = clicks[itt] = [Math.round((Math.random() * 12)), Math.round((Math.random() * 12))];
                        pixelockWidget.methods.clickGridCreate(clicks[itt][0], clicks[itt][1]);
                    }, speed * (itt + 1));
                } else {
                    setTimeout(function() {
                        pixelockWidget.methods.clickGrid(storedClicks[itt][0], storedClicks[itt][1]);
                    }, speed * (itt + 1));
                }
            }
        };
        //step 3
        $scope.lockFunctions.enterPattern = function() {
            $scope.lockFunctions.storeLock(1).then(function(data) {
                if ($scope.publicLock && isAuthorizedToStep(3)) {
                    pixelockWidget.newLock({
                        'uid': $scope.user.tag,
                        'authToken': $auth.getToken(),
                        'lockURL': $scope.lock.domain,
                        'lockName': $scope.lock.name,
                        'IDSelector': "lockCanvas",
                        'source': 1,
                        'onComplete': setRandomPattern,
                        'setup': {
                            'images': $scope.lock.images,
                            'length': $scope.lock.length,
                            'cols': 12,
                            'rows': 9,
                            'numbers': $scope.lock.numbers,
                            'uppercase': $scope.lock.uppercase,
                            'lowercase': $scope.lock.lowercase,
                            'symbols': $scope.lock.symbols
                        }
                    });
                    performRandomClicks();
                } else {
                    if (isAuthorizedToStep(3)) {
                        deleteLocks();
                        $scope.activeTab = 3;
                        if (!$scope.changePasswordState || $scope.changePasswordState && !$stateParams.id) //dont update the lock on chnage password state
                        {


                            pixelockWidget.newLock({
                                'uid': $scope.user.tag,
                                'authToken': $auth.getToken(),
                                'lockURL': $scope.lock.domain,
                                'lockName': $scope.lock.name,
                                'IDSelector': "lockCanvas",
                                'source': 1,
                                'onComplete': $scope.lockFunctions.confirmPattern,
                                'setup': {
                                    'images': $scope.lock.images,
                                    'length': $scope.lock.length,
                                    'cols': 12,
                                    'rows': 9,
                                    'definedPassword': $scope.lock.old_password,
                                    'numbers': $scope.lock.numbers,
                                    'uppercase': $scope.lock.uppercase,
                                    'lowercase': $scope.lock.lowercase,
                                    'symbols': $scope.lock.symbols
                                }
                            });
                        } else {
                            pixelockWidget.init({
                                'uid': $scope.user.tag,
                                'authToken': $auth.getToken(),
                                'lockURL': $scope.lock.domain, //this should not be required
                                'lockName': $scope.lock.name, //this should not be required
                                'IDSelector': "lockCanvas",
                                'source': 1,
                                'onComplete': $scope.lockFunctions.confirmPattern,
                                'lock': $scope.lockObj
                            });
                        }

                    }
                }
            });

        };

        //step 4
        $scope.lockFunctions.confirmPattern = function(lockData) {
            if (!!lockData) {

                if (typeof lockData === 'string') {
                    $scope.generatedPasswords.password = lockData;
                } else {
                    $scope.lockObj = lockData; //clean out all the things
                    $scope.generatedPasswords.password = $scope.lockObj.password;
                }

                $scope.activeTab = 4;
                isAuthorized = isAuthorizedToStep(4);
                $scope.$apply(); //refresh here as ran trough plugin 
            } else {
                isAuthorized = isAuthorizedToStep(4);
            }

            if (isAuthorized) {
                deleteLocks();
                //init callback return password and the other returm the data
                pixelockWidget.init({
                    'uid': $scope.user.tag,
                    'authToken': $auth.getToken(),
                    'lockURL': $scope.lock.domain, //this should not be required
                    'lockName': $scope.lock.name, //this should not be required
                    'IDSelector': "confirmLockCanvas",
                    'source': 1,
                    'onComplete': $scope.lockFunctions.viewDetails,
                    'lock': $scope.lockObj
                }).then(setFinalObj);

            }
        };

        //step 5
        $scope.lockFunctions.viewDetails = function(password) {

            if ($scope.editState) {
                $scope.activeTab = 5;
                $scope.lockFunctions.storeLock().then(function successCallback(response){
                    var editedLock = response.data;
                    deleteLocks();
                    pixelockWidget.init({
                        'uid': $scope.user.tag,
                        'authToken': $auth.getToken(),
                        'lockURL': editedLock.domain, //this should not be required
                        'lockName': editedLock.name, //this should not be required
                        'IDSelector': "lockShow",
                        'source': 1,
                        'lock': editedLock.file,
                        'onComplete': null,
                    }).then(setFinalObj);

                });


            } else {
                $scope.generatedPasswords.confirm_password = password;
                if ($scope.generatedPasswords.confirm_password !== $scope.generatedPasswords.password) {
                    ngToast.create({
                        className: 'danger',
                        content: 'Your Passwords did not match please try again.'
                    });
                }

                var isAuthorized = isAuthorizedToStep(5);
                $scope.$apply(); //refresh here as ran trough plugin }
                $scope.lockNotStepsDone = false;

                if (isAuthorized) {
                    if ($scope.registration) {

                        $scope.lockFunctions.storeLock().then(function() {
                            apiRequest.send({
                                'url': 'set-password',
                                'method': 'POST',
                                data: $scope.generatedPasswords
                            }).then(function successCallback(response) {
                                    apiRequest.removeRegistrationToken();
                                    $state.go('auth.login', {
                                        message: 'Your Account has been successfully set up you may proceed to login'
                                    });
                                },
                                function errorCallback(response) {
                                    $scope.errors = {};
                                    switch (response.status) {
                                        case 422:
                                            $scope.errors = response.data;
                                            ngToast.create({
                                                className: 'danger',
                                                content: 'Whoops, something went wrong. Refer to the highlighted fields for more information.'
                                            });
                                            break;
                                    }
                                });
                        });

                    } else if ($scope.changePasswordState) {


                        if ($stateParams.id) {
                            var data = angular.copy($scope.generatedPasswords);
                            data.lock_id = $stateParams.id;
                            data.old_password = $stateParams.old_password;
                            $scope.lockFunctions.changePasswordStore(data);

                        } else {
                            $scope.lockFunctions.storeLock().then(function(response) {
                                var data = angular.copy($scope.generatedPasswords);
                                data.lock_id = response.data.id;
                                data.old_password = $stateParams.old_password;
                                $scope.lockFunctions.changePasswordStore(data);
                            });
                        }

                    } else if ($scope.redoPasswordState) {


                        $scope.lockFunctions.storeLock().then(function(lockResponse) {
                            var data = $scope.generatedPasswords;
                            data.reset_token = $stateParams.reset_token;
                            data.lock_id = lockResponse.data.id;
                            apiRequest.send({
                                    'url': 'set-password',
                                    'method': 'POST',
                                    data: data
                                })
                                .then(function successCallback(response) {
                                        apiRequest.removeRegistrationToken();
                                        $state.go('auth.login', {
                                            message: 'Your password has been reset'
                                        });
                                    },
                                    function errorCallback(response) {
                                        $scope.errors = {};
                                        switch (response.status) {
                                            case 422:
                                                $scope.errors = response.data;
                                                ngToast.create({
                                                    className: 'danger',
                                                    content: 'Whoops, something went wrong. Refer to the highlighted fields for more information.'
                                                });
                                                break;
                                            case 500:
                                                $scope.system.message = response.data.message[1];
                                        }
                                    });
                        });
                    } else {
                        deleteLocks();
                        pixelockWidget.init({
                            'uid': $scope.user.tag,
                            'authToken': $auth.getToken(),
                            'lockURL': $scope.lock.domain, //this should not be required
                            'lockName': $scope.lock.name, //this should not be required
                            'IDSelector': "lockShow",
                            'source': 1,
                            'lock': $scope.lockObj,
                            'onComplete': null,
                        }).then(setFinalObj);
                        $scope.activeTab = 5;
                        $scope.lockFunctions.storeLock();
                    }
                }
            }


        };



        $scope.lockFunctions.changePasswordStore = function(data) {
            apiRequest.send({
                'url': 'change-password',
                'method': 'PUT',
                data: data
            }).then(function successCallback(response) {

                ngToast.create({
                    className: 'success',
                    content: 'Your password has been successfully changed.'
                });

                $state.go('dashboard');
            }, function errorCallback(response) {
                $scope.errors = {};
                switch (response.status) {
                    case 422:
                        $scope.errors = response.data;
                        ngToast.create({
                            className: 'danger',

                            content: 'Whoops, something went wrong. Refer to the highlighted fields for more information.'
                        });
                        break;
                }
            });
        };


        $scope.lockFunctions.getImageSrc = function(url) {
            for (var i = $scope.images.length - 1; i >= 0; i--) {
                if ($scope.images[i].url == url) {
                    return $scope.images[i];
                }
            }
        };


        var indexInit = function(page) {
            page = page || 1;
            var params = {
                'page': page
            };

            $scope.loading = true;
            apiRequest.send({
                'url': 'lock',
                'method': 'GET',
                'params': params
            }).then(function successCallback(response) {

                    $scope.locks = [];
                    $scope.locks.push({
                        dummyLock: true
                    });
                    $scope.locks = $scope.locks.concat(response.data.data);
                    $scope.numberOfLocks = response.data.data.length;
                    $scope.LockPagination = response.data;
                    $scope.loading = false;
                    $scope.chunkedLocks = chunk($scope.locks, 6);

                },
                function errorCallback(response) {});
        };

        var publicLocksInit = function(page) {

            page = page || 1;
            var params = {
                'page': page
            };

            apiRequest.send({
                'url': 'public-lock',
                'method': 'GET',
                'params': params
            }).then(function successCallback(response) {

                    $scope.locks = [];
                    $scope.locks.push({
                        dummyLock: true
                    });
                    $scope.locks = $scope.locks.concat(response.data.data);
                    $scope.LockPagination = response.data;
                    $scope.loading = false;
                    $scope.chunkedLocks = chunk($scope.locks, 6);


                },
                function errorCallback(response) {});
        };

        var registerInit = function() {
            $scope.registration = true;
            $scope.lock.domain = config.apiURL;
            $scope.lock.name = config.name;
            $scope.lock.length = 12;
            $scope.user = $stateParams.user;
            $scope.grouped = chunk($scope.symbols, 12);
            $scope.lockFunctions.activateSymbols();
            setGallery();
            $scope.lockFunctions.selectImages();
            if ($.isEmptyObject($scope.user) || $scope.user === null) {
                $state.go('auth.login');
            }
        };

        var redoLoginLockInit = function() {
            var reset_token = $stateParams.reset_token;

            apiRequest.send({
                'url': 'get-user-from-reset/' + reset_token,
                'method': 'GET'
            }).then(
                function successCallback(response) {
                    $scope.user = response;
                    $scope.redoPasswordState = true;
                    apiRequest.send({
                            'url': 'lock-names',
                            'params': {
                                'reset_token': reset_token
                            },
                            'method': 'GET'
                        })
                        .then(function successCallback(response) {
                            for (var i = 0; i < response.data.length; i++) {
                                $scope.lockNames.push(response.data[i].name);
                            }

                            currentlockName = config.name;
                            var count = 1;
                            while ($scope.lockNames.indexOf(currentlockName) !== -1) {
                                currentlockName = config.name + "(" + count + ")";
                                count = count + 1;
                            }
                            $scope.lock.name = currentlockName;
                            $scope.lock.length = 12;
                            $scope.grouped = chunk($scope.symbols, 12);
                            $scope.lockFunctions.activateSymbols();
                            setGallery();
                            $scope.lockFunctions.selectImages();

                        });

                });

        };

        var changePasswordLockInit = function() {
            var id = $stateParams.id;
            $scope.changePasswordState = true;

            // if the chse to use old lock simply let them enter a pattern
            if (id) {
                apiRequest.send({
                        'url': 'lock-for-edit/' + id,
                        'method': 'GET'
                    })
                    .then(function successCallback(response) {
                        $scope.lock = response.data.lock;

                        currentImages = response.data.images;
                        $scope.lockObj = response.data.lockObj;

                        //set the active symbols from the locks
                        for (var i = 0; i < $scope.symbols.length; i++) {
                            if ($scope.lock.symbols.indexOf($scope.symbols[i].value) > -1) {
                                $scope.symbols[i].allowed = true;
                            } else {
                                $scope.symbols[i].allowed = false;
                            }
                        }

                        //get all the images ones you have the lock and set the active ones
                        setGallery(1, true);
                        $scope.lockFunctions.enterPattern();

                    });
            } else {

                apiRequest.send({
                        'url': 'lock-names',
                        'method': 'GET'
                    })
                    .then(function successCallback(response) {


                        for (var i = 0; i < response.data.length; i++) {
                            $scope.lockNames.push(response.data[i].name);
                        }

                        currentlockName = config.name;
                        var count = 1;
                        while ($scope.lockNames.indexOf(currentlockName) !== -1) {
                            currentlockName = config.name + "(" + count + ")";
                            count = count + 1;
                        }
                        $scope.lock.name = currentlockName;
                        $scope.lock.length = 12;
                        $scope.grouped = chunk($scope.symbols, 12);
                        $scope.lockFunctions.activateSymbols();
                        setGallery();
                        $scope.lockFunctions.selectImages();

                    });
                // initCreate();
            }
        };

        var initEdit = function() {
            var id = $stateParams.id;
            $scope.disableLockFields = true;
            $scope.editState = true;
            apiRequest.send({
                    'url': 'lock-for-edit/' + id,
                    'method': 'GET'
                })
                .then(function successCallback(response) {
                    $scope.lock = response.data.lock;
                    currentImages = response.data.images;

                    //set the active symbols from the locks
                    for (var i = 0; i < $scope.symbols.length; i++) {
                        if ($scope.lock.symbols.indexOf($scope.symbols[i].value) > -1) {
                            $scope.symbols[i].allowed = true;
                        } else {
                            $scope.symbols[i].allowed = false;
                        }
                    }
                    //get all the images ones you have the lock and set the active ones
                    setGallery(1, true);
                });
            $scope.grouped = chunk($scope.symbols, 12);
            $scope.lockFunctions.activateSymbols();
        };

        var initDuplicate = function() {

            apiRequest.send({
                    'url': 'lock-for-edit/' + $stateParams.id,
                    'method': 'GET'
                })
                .then(function successCallback(response) {
                    //remove all the things not needed for duplicate
                    response.data.lock.id = null;
                    response.data.lock.name = null;
                    response.data.lock.domain = null;

                    $scope.lock = response.data.lock;

                    currentImages = response.data.images;

                    //set the active symbols from the locks
                    for (var i = 0; i < $scope.symbols.length; i++) {
                        if ($scope.lock.symbols.indexOf($scope.symbols[i].value) > -1) {
                            $scope.symbols[i].allowed = true;
                        } else {
                            $scope.symbols[i].allowed = false;
                        }
                    }

                    //get all the images ones you have the lock and set the active ones
                    setGallery(1, true);


                });


            $scope.grouped = chunk($scope.symbols, 12);
            $scope.lockFunctions.activateSymbols();
        };


        var setGallery = function(page, populate) {
            page = page || 1;
            populate = populate || false;
            var params = {
                'page': page,
                'inc_default': true
            };

            if ($state.current.name == 'redoLoginLock') {
                var data = $scope.lock;
                params.reset_token = $stateParams.reset_token;
            }

            apiRequest.send({
                    'url': 'image',
                    'method': 'GET',
                    'params': params
                })
                .then(function successCallback(response) {

                    $scope.imagesPaginate = response.data;
                    $scope.images = [];
                    images = response.data.data;

                    //set all the images if it is an edit

                    $scope.images.push({
                        'dummyImage': true
                    });

                    $scope.images = $scope.images.concat(images);

                    angular.forEach($scope.images, function(image) {
                        image.orders = [];
                    });

                    //set the orders of the images
                    angular.forEach($scope.images, function(image) {
                        image.orders = [];
                        for (var i = 0; i < $scope.lock.images.length; i++) {
                            if ($scope.lock.images[i] === image.url) {
                                order = i + 1;
                                image.orders.push(order);
                            }
                        }
                    });

                    $scope.chunkedImages = chunk($scope.images, 6);
                });
        };

        var initCreate = function() {

            $scope.loading = true;
            setGallery();
            $scope.grouped = chunk($scope.symbols, 12);
            $scope.lockFunctions.activateSymbols();
        };

        $scope.lockFunctions.goToCreate = function() {

            if (parseInt($scope.user.limit) <= $scope.numberOfLocks) {
                var modalInstance = $uibModal.open({
                    animation: $scope.animationsEnabled,
                    templateUrl: 'views/elements/alert.html',
                    controller: 'messageAlertController',
                    backdrop: 'static',
                    resolve: {
                        items: function() {
                            return {
                                'heading': 'Lock Limit',
                                'content': "You Have reached the limit of uploads."
                            };
                        }
                    }
                });
            } else {
                $state.go("lockCreate");
            }

        };

        var initPublicCreate = function() {
            $scope.publicLock = true;
            $scope.loading = true;
            apiRequest.send({
                'url': 'category/list',
                'method': 'GET'
            }).then(function successCallback(response) {
                    $scope.categoryList = response.data;
                },
                function errorCallback(response) {});

            setGallery();

            $scope.grouped = chunk($scope.symbols, 12);
            $scope.lockFunctions.activateSymbols();
        };


        //either set gallery or set the data of the paginator
        $scope.$on('paginate.changed', function(evt, data) {
            switch ($state.current.name) {
                case 'dashboard':
                    indexInit(data);
                    break;
                case 'adminPublicLocks':
                    publicLocksInit(data);
                    break;
                case 'lockCreate':
                    setGallery(data);
                    break;
                case 'registerLock':
                    setGallery(data);
                    break;
                case 'changePasswordLock':
                    setGallery(data);
                    break;
                case 'lockEdit':
                    setGallery(data);
                    break;
                case 'lockDuplicate':
                    setGallery(data);
                    break;
                case 'adminPublicLockCreate':
                    setGallery(data);
                    break;
                case 'redoLoginLock':
                    setGallery(data);
                    break;
            }
        });

        //based on the state name either show the index page or allow creation of a lock
        switch ($state.current.name) {
            case 'dashboard':
                indexInit();
                break;
            case 'registerLock':
                registerInit();
                break;
            case 'changePasswordLock':
                changePasswordLockInit();
                break;
            case 'lockCreate':
                initCreate();
                break;
            case 'lockEdit':
                initEdit();
                break;
            case 'lockDuplicate':
                initDuplicate();
                break;
            case 'adminPublicLocks':
                publicLocksInit();
                break;
            case 'adminPublicLockCreate':
                initPublicCreate();
                break;
            case 'redoLoginLock':
                redoLoginLockInit();
                break;
        }

    }
]);

/**
 * Converts data uri to Blob. Necessary for uploading.
 * @see
 *   http://stackoverflow.com/questions/4998908/convert-data-uri-to-file-then-append-to-formdata
 * @param  {String} dataURI
 * @return {Blob}
 */
var dataURItoBlob = function(dataURI) {
    var binary = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var array = [];
    for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
        type: mimeString
    });
};



pixelockControllers.controller('LockConfirmController', function($scope, $uibModalInstance) {
    $scope.choice = function(option) {
        $uibModalInstance.close(option);
    };
});

pixelockControllers.controller('PasswordController', ['$rootScope', '$scope', '$state', '$uibModal', '$auth', 'ngToast', 'apiRequest', 'cookieManagement', function($rootScope, $scope, $state, $uibModal, $auth, ngToast, apiRequest, cookieManagement) {
    $scope.password = null;
    $scope.lockObj = {};

    $scope.changePassword = function() {
        $rootScope.$broadcast('CLEAR_ALERT');
        $scope.activeTab = 0;
        var user = cookieManagement.getCookie('user');

        //get the users login lock and set it in the widegt to it
        apiRequest.send({
            'url': 'get-login-lock',
            'method': 'POST',
            'data': {
                'email': user.email
            }
        }).then(function successCallback(response) {
            pixelockWidget.init({
                'uid': response.data.data.tag,
                'lockURL': response.data.data.uri,
                'lockName': response.data.data.name,
                'IDSelector': "lockCanvas",
                'authToken': $auth.getToken(),
                'onComplete': $scope.confirmPassword,
                'source': 1
            }).then(function(lock) {
                $scope.$apply(function() {
                    $scope.lockObj = lock;
                    $scope.lockContent = lock.getLock();
                });
            });

        });

    };
    var modalInstance;
    var selectLockModalInstance;

    $scope.confirmPassword = function() {
        $scope.$apply();
        $scope.password = $scope.lockObj.getGeneratedPassword();
        apiRequest.send({
            'url': 'confirm-password',
            'method': 'POST',
            'data': {
                'password': $scope.password
            }
        }).then(function successCallback(response) {
            modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'views/lock/password-new-lock.html',
                controller: 'LockConfirmController',
                size: 'sm'
            });

            modalInstance.result.then(function(newLock) {
                if (newLock) {
                    $state.go("changePasswordLock", {
                        'old_password': $scope.password
                    });
                } else {
                    modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: 'views/lock/select-lock.html',
                        controller: 'LockSelectController',
                        size: 'lg',
                        resolve: {
                            old_password: function() {
                                return $scope.password;
                            }
                        }
                    });
                }
            });

        }, function errorCallback(response) {
            $scope.errors = {};
            switch (response.status) {
                case 422:
                    $scope.errors = response.data;
                    ngToast.create({
                        className: 'danger',
                        content: 'Whoops, something went wrong. Refer to the highlighted fields for more information.'
                    });
                    break;
            }
        });
    };

    $scope.changePassword();
}]);

pixelockControllers.controller('LockSelectController', ['$scope', '$state', '$uibModalInstance', 'old_password', 'ngToast', 'apiRequest', function($scope, $state, $uibModalInstance, old_password, ngToast, apiRequest) {
    $scope.locks = [];
    $scope.chunkedLocks = [];
    apiRequest.send({
        'url': 'lock',
        'method': 'GET'
    }).then(function successCallback(response) {
            $scope.locks = response.data.data;
            $scope.chunkedLocks = chunk($scope.locks, 5);

        },
        function errorCallback(response) {});

    $scope.goToLock = function(id) {
        $uibModalInstance.dismiss();
        $state.go("changePasswordLock", {
            'old_password': old_password,
            'id': id
        });
    };

}]);

pixelockControllers.controller('ProfileController', ['$rootScope', '$scope', '$uibModal', '$location', 'ngToast', 'apiRequest', 'cookieManagement', function($rootScope, $scope, $uibModal, $location, ngToast, apiRequest, cookieManagement) {
    $scope.profile = {
        "id": "",
        "name_first": "",
        "name_last": "",
        "email": ""
    };
    $scope.dashdata = null;


    apiRequest.send({
        'url': 'get-dash',
        'method': 'GET'
    }).then(function successCallback(response) {
        $scope.dashdata = response.data;
    }, function errorCallback(response) {

    });

    $scope.profileFunctions = {
        "edit": false,
        "loaded": false,
        "view": 0
    };
    $scope.profileFunctions.editProfile = function() {
        $rootScope.$broadcast('CLEAR_ALERT');
        $scope.profileFunctions.edit = true;
    };
    $scope.profileFunctions.viewProfile = function() {
        $rootScope.$broadcast('CLEAR_ALERT');
        $scope.profileFunctions.view = 0;
    };

    $scope.profileFunctions.remove = function() {
        var modalInstance = $uibModal.open({
            templateUrl: 'views/profile/remove.html',
            controller: 'removeUserController',
        });
    };




    $scope.profileFunctions.update = function() {
        $('#profile_update').button('loading');
        apiRequest.send({
            'url': 'profile',
            'method': 'PUT',
            'data': $scope.profile
        }).then(function successCallback(response) {
            $('#profile_update').button('reset');
            if (response.data.message[0] === 'success') {
                $scope.profileFunctions.edit = false;
            }
            $rootScope.$broadcast('SET_ALERT', response.data.message);
        }, function errorCallback(response) {

            $('#profile_update').button('reset');
            $scope.registerErrors = {};
            switch (response.status) {
                case 422:
                    $scope.errors = response.data;
                    ngToast.create({
                        className: 'danger',
                        content: 'Whoops, something went wrong. Refer to the highlighted fields for more information.'
                    });
                    break;
            }
        });
    };

    initProfile = function() {
        $rootScope.$broadcast('START_LOADING');
        apiRequest.send({
            'url': 'profile',
            'method': 'POST'
        }).then(function successCallback(response) {
            $rootScope.$broadcast('STOP_LOADING');
            $scope.profileFunctions.loaded = true;
            $scope.profile = response.data;
        });
    };

    initProfile();
}]);

//removeUserController
pixelockControllers.controller('removeUserController', ['$scope', '$uibModalInstance', '$state', 'ngToast', 'apiRequest', 'config', function($scope, $uibModalInstance, $state, ngToast, apiRequest, config) {
    $scope.remove = function() {
        apiRequest.send({
            'url': 'remove-account',
            'method': 'DELETE'
        }).then(function successCallback(response) {
            ngToast.create({
                className: 'success',
                content: 'Your account has been successfully removed, Thank you for using pixelock'
            });
            $uibModalInstance.close(response);
            $state.go('publicindex');


        }, function errorCallback(response) {
            ngToast.create({
                className: 'danger',
                content: response.data.message[1]
            });
            $uibModalInstance.close(response);

        });
    }

}]);

pixelockControllers.controller('helpController', ['$rootScope', '$scope', '$state', '$stateParams', 'Upload', '$uibModal', 'ngToast', 'apiRequest', 'config', function($rootScope, $scope, $state, $stateParams, Upload, $uibModal, ngToast, apiRequest, config) {
    helpFunctions = {};
    $scope.HelpFunctions = {};
    var edit;


    $scope.HelpFunctions.show = function($data) {

        var modalInstance = $uibModal.open({
            templateUrl: 'views/help/show.html',
            controller: 'adminHelpShowController',
            resolve: {
                items: function() {
                    return $data;
                }
            }
        });
    };

    $scope.HelpFunctions.delete = function(data) {
        $scope.helpData = {};
        apiRequest.send({
            'url': 'help/' + data.id,
            'method': 'Delete'
        }).then(function successCallback(response) {
            apiRequest.send({
                'url': 'help'
            }).then(function successCallback(response) {
                $scope.helpData = response.data;
            });
        });

    };

    helpFunctions.create = function() {
        $scope.help = {
            video: '',
            title: '',
            content: '',
            video_content: '',
            excerpt: '',
            video_url: '',
            thumbnail_url: ''
        };
        $scope.video = {

        };
    };

    helpFunctions.index = function() {
        $scope.helpData = {};
        apiRequest.send({
            'url': 'help'
        }).then(function successCallback(response) {
            $scope.helpData = response.data;
        });
    };


    helpFunctions.edit = function(data) {
        edit = true;
        if (angular.isUndefined(data)) {
            var url = 'help/' + $stateParams.id + '/edit';
            apiRequest.send({
                'url': url
            }).then(function successCallback(response) {
                $scope.help = response.data;
            });
        } else {
            $scope.help = data;
        }
    };

    urlMatcher = function(urlVar) {

        var matchUrl = urlVar;
        angular.forEach($state.get(), function(state) {


            pattern = state.url.replace(/{.*?}/g, '[\\w]{1,9999}') + "$";

            var re = new RegExp(pattern);
            var same = Boolean(urlVar.match(re));
            //http://pixelock.app.local/#/auth/login
            if (same) {
                matchUrl = state.url;
            }

        });

        return matchUrl;
    }

    // upload on file select or drop
    $scope.HelpFunctions.save = function() {

        var method = "POST";
        var url = config.apiURL + "help";

        if (edit) {
            url = config.apiURL + "help/" + $scope.help.id;
            $scope.help._method = "PUT";
        }

        //remove the url of app and only check the important part
        if ($scope.help.page_url) {
            helpUrl = $scope.help.page_url.replace(config.appURL, '');
            helpUrl = $scope.help.page_url.replace(config.appURL + "#", '');
            $scope.help.page_url = urlMatcher(helpUrl);
        }


        Upload.upload({
            url: url,
            data: $scope.help
        }).then(function(resp) {
            $state.go('adminHelp');
        }, function(response) {
            switch (response.status) {
                case 422:
                    $scope.errors = response.data;
                    ngToast.create({
                        className: 'danger',
                        content: 'Whoops, something went wrong. Refer to the highlighted fields for more information.'
                    });
                    break;
            }
        }, function(evt) {
            var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
        });
    };

    $scope.changed = function(video) {
        $scope.help.video = video;
    };

    $scope.Imagechanged = function(image) {
        $scope.help.thumbnail = image;
    };


    switch ($state.current.name) {
        case 'adminHelp':
            helpFunctions.index();
            break;
        case 'adminAddHelp':
            helpFunctions.create();
            break;
        case 'adminEditHelp':
            if ($stateParams.help !== null) {
                helpFunctions.edit($stateParams.help);
            } else {
                helpFunctions.edit();
            }
            break;
    }
}]);

pixelockControllers.controller('testimonialController', ['$rootScope', '$stateParams', '$scope', '$state', '$uibModal', 'ngToast', 'apiRequest', 'config', function($rootScope, $stateParams, $scope, $state, $uibModal, ngToast, apiRequest, config) {
    testimonialFunctions = {};
    $scope.TestimonialFunctions = {};
    var edit = false;

    $scope.TestimonialFunctions.show = function($data) {

        var modalInstance = $uibModal.open({
            templateUrl: 'views/testimonials/show.html',
            controller: 'testimonialShowController',
            resolve: {
                items: function() {
                    return $data;
                }
            }
        });
    };

    testimonialFunctions.create = function() {
        $scope.testimonial = {
            author: '',
            content: ''
        };
    };

    testimonialFunctions.edit = function(data) {
        edit = true;
        if (angular.isUndefined(data)) {
            var url = 'testimonial/' + $stateParams.id + '/edit';
            apiRequest.send({
                'url': url
            }).then(function successCallback(response) {
                $scope.testimonial = response.data;
            });
        } else {
            $scope.testimonial = data;
        }
    };


    testimonialFunctions.index = function() {
        $scope.testimonialData = {};
        apiRequest.send({
            'url': 'testimonial'
        }).then(function successCallback(response) {
            $scope.testimonials = response.data;
        });
    };

    $scope.TestimonialFunctions.delete = function(data) {
        $scope.testimonialData = {};
        apiRequest.send({
            'url': 'testimonial/' + data.id,
            'method': 'Delete'
        }).then(function successCallback(response) {
            apiRequest.send({
                'url': 'testimonial'
            }).then(function successCallback(response) {
                $scope.testimonials = response.data;
            });
        });

    };



    $scope.TestimonialFunctions.save = function() {

        var method = "POST";
        var url = "testimonial";

        if (edit) {
            method = "PUT";
            url = "testimonial/" + $scope.testimonial.id;
        }
        apiRequest.send({
            method: method,
            url: url,
            data: $scope.testimonial
        }).then(function(response) {
            $state.go('adminTestimonial');
        }, function(response) {
            switch (response.status) {
                case 422:
                    $scope.errors = response.data;
                    ngToast.create({
                        className: 'danger',
                        content: 'Whoops, something went wrong. Refer to the highlighted fields for more information.'
                    });
                    break;
            }
        });
    };

    switch ($state.current.name) {
        case 'adminTestimonial':
            testimonialFunctions.index();
            break;
        case 'adminAddTestimonial':
            testimonialFunctions.create();
            break;
        case 'adminEditTestimonial':
            if ($stateParams.testimonial !== null) {
                testimonialFunctions.edit($stateParams.testimonial);
            } else {
                testimonialFunctions.edit();
            }
            break;
    }
}]);

pixelockControllers.controller('testimonialShowController', ['$scope', '$state', '$sce', '$uibModalInstance', 'items', function($scope, $state, $sce, $uibModalInstance, items) {
    $scope.testimonial = items;
}]);

pixelockControllers.controller('adminHelpShowController', ['$scope', '$state', '$sce', '$uibModalInstance', 'items', function($scope, $state, $sce, $uibModalInstance, items) {
    $scope.help = items;
}]);


pixelockControllers.controller('userController', ['$scope', '$location', '$state', '$uibModal', 'ngToast', 'apiRequest', 'cookieManagement', function($scope, $location, $state, $uibModal, ngToast, apiRequest, cookieManagement) {

    $scope.users = [];
    $scope.userFunctions = {};

    var IndexInit = function(page) {
        page = page || 1;
        var params = {
            'page': page
        };

        apiRequest.send({
            'url': 'manage-users',
            'method': 'GET',
            'params': params
        }).
        then(function successCallback(response) {
            $scope.users = response.data.data;
            $scope.usersPaginate = response.data;
        }, function errorCallback(response) {});
    };

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.userFunctions.edit = function(user) {

        var modalInstance = $uibModal.open({
            templateUrl: 'views/users/update.html',
            controller: 'editUserController',
            backdrop: 'static',
            resolve: {
                user: function() {
                    return user;
                }
            }
        });

        modalInstance.result.then(function(response) {
            $scope.users = response.data.data;
            $scope.usersPaginate = response.data;
        });

    };

    $scope.$on('paginate.changed', function(evt, data) {
        IndexInit(data);
    });

    IndexInit();

}]);

pixelockControllers.controller('editUserController', ['$scope', '$uibModalInstance', 'ngToast', 'apiRequest', 'user', function($scope, $uibModalInstance, ngToast, apiRequest, user) {
    $scope.user = user;
    $scope.store = function() {
        apiRequest.send({
                'url': 'manage-user/' + user.id,
                'method': 'POST',
                'data': $scope.user
            })
            .then(function successCallback(response) {
                $uibModalInstance.close(response);


            }, function errorCallback(response) {


                $scope.errors = {};
                switch (response.status) {
                    case 422:
                        $scope.errors = response.data;
                        ngToast.create({
                            className: 'danger',
                            content: 'Whoops, something went wrong. Refer to the highlighted fields for more information.'
                        });
                        break;
                }
            });

    };

}]);