/*var env = {
	"apiURL": "http://pixelock.local/",
	"appURL": "http://pixelock.app.local/"
};*/
/*var env = {
	"apiURL":"http://dev1.assemble.co.za/pixelock/api/public/",
	"appURL":"http://dev1.assemble.co.za/pixelock/app/"
};*/

var env = {
    apiURL: 'https://www.pixelock.com/api/public/',
    appURL: 'https://www.pixelock.com/'
};
