var pixelockDirectives = angular.module('pixelockDirectives', []);

  pixelockDirectives.directive('paginate',[function () {
 	return {
        restrict : "E",
         scope: {
      		data: '=pagedata'
    	},
    	link: function(scope)
    	{
    	  
		   var paginate = function( data ) {

	   		scope.current_page = data.current_page;
    		scope.last_page = data.last_page;
    		scope.pages = [];
    		var addPage = 4;
            var remPage = 3;
            if(scope.last_page-scope.current_page < 3)
            {
               remPage += 3 - (scope.last_page-scope.current_page);
            }

  
	         	for(var i = scope.current_page - remPage; i<scope.current_page + addPage;i++)
	         	{
	         		if(i > 0 )
	         		{
	         			if(i <= scope.last_page)
	         			{
	         				
	         				scope.pages.push(i);
	         			}
	         		}
	         		else
	         		{
	         			addPage++;
	         		}
	         	}
		   };

		   scope.pageChange = function(newPage)
		   {
		   		scope.$emit('paginate.changed', newPage );
		   };

    	scope.$watch( 'data', paginate );

    	},
    	templateUrl: 'views/elements/pagination.html'
    };

}]);
