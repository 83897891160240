var pixelockApp = angular.module('pixelockApp', [
  'ui.router',
  'pixelockControllers',
  'pixelockDirectives',
  'satellizer',
  'ngCookies',
  'ngImgCrop',
  'ui.bootstrap',
  'angular-clipboard',
  'rzModule',
  'ngFileUpload',
  'textAngular',
  'ngToast',
  'ngTouch'
]).constant('config',{
  //apiURL:'http://localhost/pixelock.co.za/dev/api/public/',
//  appURL:'http://localhost/pixelock.co.za/dev/www/'
  apiURL: env.apiURL,
  appURL: env.appURL,
  name:'Pixel Lock'

  }).config(['$stateProvider','$urlRouterProvider','$authProvider','config',function($stateProvider,$urlRouterProvider,$authProvider,config) {
    $authProvider.loginUrl = config.apiURL+'login';

    
    
    $urlRouterProvider.otherwise('/');

    $stateProvider.state('auth', {
      url: '/auth',
      templateUrl: 'views/auth/auth.html',
      controller:'AuthController',
      params : {message:null},
    }).state('auth.login', {
      url: '/login',
      templateUrl: 'views/auth/login.html',
      roles:['guest'],
      params : {message:null}
    })
    .state('auth.resetLock', {
      url: '/reset-login-lock',
      templateUrl: 'views/auth/reset-login-lock.html',
      roles:['guest']
    })
    .state('auth.completeRegistration', {
      url: '/register/{token}',
      templateUrl: 'views/auth/completeRegistration.html',
      controller:'completeRegistrationController',
      roles:['guest']
    })
    .state('auth.resetPassword', {
      url: '/reset-password/{token}/{email}',
      templateUrl: 'views/auth/reset.html',
      controller:'AuthController',
      roles:['guest']
    }).state('auth.register', {
      url: '/register',
      templateUrl: 'views/auth/register.html',
      roles:['guest']
    }).state('dashboard', {
      url: '/dashboard',
      templateUrl: 'views/lock/index.html',
      controller:'LockController',
      roles:['guest']
    })
    .state('statistics',{
        url: '/statistics',
        roles:['loggedin'],
        templateUrl: 'views/profile/statistics.html',
        controller:'ProfileController'
    }).state('profile',{
        url: '/profile',
        templateUrl: 'views/profile/profile.html',
        controller:'ProfileController',
        roles:['guest','loggedin']
    }).state('changePassword',{
        url: '/change-password',
        templateUrl: 'views/profile/change-password.html',
        controller:'PasswordController',
        roles:['guest','loggedin']
    }).state('lockView', {
      url: '/lock-view/{id}',
      templateUrl: 'views/lock/view.html',
      controller:'viewLockController',
      params : {lock:null,id:null},
      roles:['loggedin']
    })
    .state('lockEdit', {
      url: '/lock-edit/{id}',
      templateUrl: 'views/lock/create.html',
      controller:'LockController',
      params : {id:null},
      roles:['loggedin']
    })
    .state('lockDuplicate', {
      url: '/lock-duplicate/{id}',
      templateUrl: 'views/lock/create.html',
      controller:'LockController',
      params : {id:null},
      roles:['loggedin']
    })
    .state('registerLock', {
      url: '/lock-register',
      templateUrl: 'views/lock/create.html',
      controller:'LockController',
      roles:['guest'],
      params : {user:null}
    })
    .state('lockCreate', {
      url: '/lock-create',
      templateUrl: 'views/lock/create.html',
      controller:'LockController',
      params : {lock_id:null,user:null,lockUrl:null},
      roles:['loggedin']
    })
    .state('redoLoginLock', {
      url: '/redo-login-lock/{reset_token}',//this has been hardcoded on the redo to the server
      templateUrl: 'views/lock/create.html',
      controller:'LockController',
      roles:['guest'],
      params : {reset_token:null,lock_id:null,user:null,lockUrl:null}
    })
    .state('changePasswordLock', {
      url: '/change-password-lock',
      templateUrl: 'views/lock/create.html',
      controller:'LockController',
      params : {old_password:null,id:null,user:null,lockUrl:null},
      roles:['loggedin']
    }).state('adminPublicLocks', {
      url: '/admin/public/locks',
      templateUrl: 'views/lock/index.html',
      controller:'LockController',
      roles:['loggedin']
    })
    .state('adminPublicLockCreate', {
      url: '/admin/public/lock-create',
      templateUrl: 'views/lock/create.html',
      controller:'LockController',
      roles:['loggedin']
    }).state('adminAddHelp', {
      url: '/admin/public/add-help',
      templateUrl: 'views/help/form.html',
      controller:'helpController',
      roles:['loggedin']
    })
    .state('adminEditHelp', {
      url: '/admin/public/edit-help/{id}',
      templateUrl: 'views/help/form.html',
      params : {id:null,help:null},
      controller:'helpController',
      roles:['loggedin']
    })
    .state('adminHelp', {
      url: '/admin/public/manage-help',
      templateUrl: 'views/help/index.html',
      controller:'helpController',
      roles:['loggedin']
    })
    .state('adminTestimonial', {
      url: '/admin/public/manage-testimonial',
      templateUrl: 'views/testimonials/index.html',
      controller:'testimonialController',
      roles:['loggedin']
    })
    .state('adminAddTestimonial', {
      url: '/admin/public/add-testimonials',
      templateUrl: 'views/testimonials/form.html',
      controller:'testimonialController',
      roles:['loggedin']
    }).state('adminEditTestimonial', {
      url: '/admin/public/edit-testimonials/{id}',
      templateUrl: 'views/testimonials/form.html',
      controller:'testimonialController',
      params : {testimonial:null,id:null},
      roles:['loggedin']
    })
    .state('adminUsers', {
      url: '/admin/users',
      templateUrl: 'views/users/index.html',
      controller:'userController',
      roles:['loggedin']
    })
    .state('categories', {
      url: '/categories',
      templateUrl: 'views/category/index.html',
      controller:'categoryController',
      roles:['loggedin']
    })
    .state('gallery', {
      url: '/gallery',
      templateUrl: 'views/gallery/index.html',
      controller:'GalleryController',
      roles:['loggedin']
    }).state('publicindex', {
      url: '/',
      templateUrl: 'views/public/index.html',
      controller:'offlineController',
      roles:['guest','loggedin'],
    }).state('publicTerms', {
      url: '/terms-and-conditions',
      templateUrl: 'views/public/terms.html',
      controller:'offlineController',
      roles:['guest','loggedin'],
    }).state('publicContact', {
      url: '/contact-us',
      templateUrl: 'views/public/contact.html',
      controller:'offlineController',
      roles:['guest','loggedin']
    }).state('lite', {
      url: '/lite',
      templateUrl: 'views/lite/index.html',
      controller:'LiteController',
      roles:['loggedin','guest']
    }).state('liteView', {
      url: '/lite/view/{id}',
      templateUrl: 'views/lite/show.html',
      controller:'LiteController',
      params : {lock:null,id:null},
      roles:['loggedin','guest']
    }).state('liteCategory', {
      url: '/lite/category/{categoryId}/{categoryName}',
      templateUrl: 'views/lite/category.html',
      controller:'LiteController',
      roles:['loggedin','guest']
    });

 

  }])
  .service('systemService',  ['$rootScope','$http','$q','$auth','$state','$location','config','cookieManagement',
  function($rootScope,$http,$q,$auth,$state,$location,config,cookieManagement) {
    
    this.getFullUrl = function()
    {
        return $location.absUrl();
    };

    this.setOrders = function(srcArray,mapArray)
      {
        angular.forEach(srcArray, function(id){
          orders = [];
          for (var i = 0; i < mapArray.length; i++) {
            console.log(mapArray[i]);
            if(mapArray[i] === id)
            {
              order = i+1;
              orders.push(order);
            }
          }
        });
        return orders;
      };

  }])
  .service('apiRequest', ['$rootScope','$http','$q','$auth','$state','config','cookieManagement',
  function($rootScope,$http,$q,$auth,$state,config,cookieManagement) {


      this.setRegistrationToken = function(token)
      {
        cookieManagement.setCookie('registerToken',token);
      };

      this.removeRegistrationToken = function()
      {
        cookieManagement.removeCookie('registerToken');
      };


      this.send = function (data)
      {
        
        var intendedState = $state.current;

        var httpData = data;
        var promise = $q.defer();
        var isGet = angular.isDefined(data.method) && data.method.toUpperCase() == "GET";

        httpData.url = config.apiURL + data.url;
        
        if(cookieManagement.getCookie('registerToken') !== null)
        {
          var token = cookieManagement.getCookie('registerToken');
          if(isGet && angular.isDefined(token)){
            

            if(angular.isUndefined(httpData.params))
            {
              httpData.params = {'token':token};
            }else
            {
              httpData.params.token = token;
            }

          }
          else if(angular.isUndefined(httpData.data) && angular.isDefined(token))
          {
            dataValues = {'token':token};

            if(isGet)
            {
              httpData.params = dataValues;
            }else
            {
              httpData.data = dataValues;
            }
          }else if(angular.isDefined(token))
          {

            if(isGet)
            {
              httpData.params.token = token;

            }else
            {
              httpData.data.token = token;

            }
          }
        }
        //loading
        $("#pixelockApp-overlay").addClass('active');
      $http(httpData).then(function(response){
         promise.resolve(response);
          $("#pixelockApp-overlay").removeClass('active');
      },function(response){
         $("#pixelockApp-overlay").removeClass('active');
        if(response.status == 400 || response.status == 401 || response.status == 403)
        {
          //@todo please login flash message
          $rootScope.$broadcast("SESSIONEXPIRED",{intendedState:intendedState});
        }
        promise.reject(response);
      });
      return promise.promise;

      };

      //https://gist.github.com/ghinda/8442a57f22099bdb2e34
      var objectToFormData = function(obj, form, namespace) {
      var fd = form || new FormData();
      var formKey;

      for(var property in obj) {
        if(obj.hasOwnProperty(property)) {

          if(namespace) {
            formKey = namespace + '[' + property + ']';
          } else {
            formKey = property;
          }

          // if the property is an object, but not a File,
          // use recursivity.
          
          if((typeof obj[property] === 'object' && !(obj[property] instanceof File || obj[property] instanceof Blob)) ) {

            objectToFormData(obj[property], fd, property);

          } else {
            // if it's a string or a File object
            fd.append(formKey, obj[property]);
          }
        }
      }
      return fd;

    };

      this.sendWithFile = function (data) {
       var intendedState = $state.current;


        var promise = $q.defer();
        var isGet = angular.isDefined(data.method) && data.method.toUpperCase() == "GET";
        if(cookieManagement.getCookie('registerToken') !== null)
        {
          var token = cookieManagement.getCookie('registerToken');
          if(angular.isUndefined(data.params) && angular.isDefined(token))
          {
            dataValues = {'token':token};
            if(isGet)
            {
              data.data = dataValues;
            }else
            {
              data.params = dataValues;
            }
          }else if(angular.isDefined(token))
          {
            if(isGet)
            {
              data.params.token = token;
            }else
            {
              data.params.token = token;
            }
          }
        }

        data.url = config.apiURL + data.url;
        var formFields = data.data;
        data.transformRequest = angular.identity;
        data.headers = {'Content-Type': undefined};
        data.data = objectToFormData(formFields);

        $http(data).then(function(response){
           promise.resolve(response);
        },function(response){
          if(response.status == 400 || response.status == 401 || response.status == 403)
          {
            $rootScope.$broadcast("SESSIONEXPIRED",{intendedState:intendedState});
          }
          promise.reject(response);
        });

        return promise.promise;


      };

  }]).service('cookieManagement',['$cookies',function($cookies){

    this.setCookie= function(name,data,options)
    {
       $cookies.putObject(name,data,options);
    };

    this.getCookie= function(name)
    {
      return $cookies.getObject(name);
    };

    this.removeCookie= function(name)
    {
      $cookies.remove(name);
    };


  }]).factory('beforeUnload',['$rootScope','$window',function ($rootScope, $window) {
   // gist https://gist.github.com/981746/3b6050052ffafef0b4df
  //Events are broadcast outside the Scope Lifecycle
    
   $window.onbeforeunload = function () {
       var confirmation = {};
       var event = $rootScope.$broadcast('onBeforeUnload', confirmation);
       console.log(confirmation.message);
       //if (event.defaultPrevented) {
           return confirmation.message;
       //}
   };
    
    $window.onunload = function () {
        $rootScope.$broadcast('onUnload');
    };

   $window.onload = function () {
        $rootScope.$broadcast('onLoad');
    };

    return {};
}])
.run(function (beforeUnload) {
    // Must invoke the service at least once
}).factory('Help', function() {
   var helptext = '';
   return {
     get: function() { return helptext; },
     set: function(text) { helptext = text; }
   };
});

